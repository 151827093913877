<template>
    <div class="px-3 py-2">
        <v-form
            ref="formcc"
            lazy-validation 
        >
        <b-sidebar backdrop id="purchase_expenses" width="700px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة مهمة" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{ lang.add_expenses }}</span>
                </div>
                <div @click="hide" id="hideAddUnit" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
            </div>
            <v-divider></v-divider>
            <v-row class="m-2" style="direction:rtl">
                <v-col cols="12" md="4">
                    <label>نوع المصروف</label>
                    <b-form-select class="selborder" 
                        v-model="expenses.e_type" :options="expenseTypes" />
                </v-col>
                <v-col cols="12" md="4">
                    <label>العملة</label>
                    <b-form-select class="selborder" 
                        v-model="expenses.e_type" :options="curencies" />
                </v-col>
                <v-col cols="12" md="4">
                    <label>نوع الضريبة</label>
                    <b-form-select class="selborder" 
                        v-model="expenses.vattype" :options="vated" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>رقم الفاتورة</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.invid" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>المورد</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.vendor" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>الرقم الضريبي</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.vat_number" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>التاريخ</label>
                    <b-form-input class="inborder" 
                        type="date"
                        v-model="expenses.date" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>قيمة المصروف</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.amount" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>الضريبة</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.vat" />
                </v-col>
                <v-col cols="12" md="3">
                    <label>الاجمالي مع الضريبة</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.ftotal" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>البيان</label>
                    <b-form-input class="inborder" 
                        v-model="expenses.notes" />
                </v-col>
                <v-col cols="12" md="6">
                    <label>رقم الحساب</label>
                    <b-form-input class="inborder" v-model="expenses.classid" list="classes-list" />
                    <datalist id="classes-list">
                        <option v-for="(item,index) in classes" :key="index">{{item.classid}} - {{ item.namear }}</option>
                    </datalist>
                </v-col>
            </v-row>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addUnit()" variant="success" class="ma-2" style="width:100px;">{{ lang.save }}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            id: 0,
            expenses: {
                e_type: 1,
                notes: '',
                classid: '',
                amount: '',
                vattype: 1,
                vat: '',
                ftotal: '',
                date: '',
                invid: '',
                vendor: '',
                vat_number: ''
            },
            classes: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        expenseTypes: function(){
            return [
                {text: 'مصاريف تشغيلية', value: 1},
                {text: 'مصاريف غير تشغيلية', value: 2},
            ]
        },
        curencies: function(){
            return [
                {text: 'ريال سعودي', value: 1},
                {text: 'US Dolar', value: 2},
            ]
        },
        vated: function(){
            return [
                {text: 'غير خاضع للضريبة', value: 1},
                {text: 'شامل الضريبة', value: 2},
                {text: 'غير شامل الضريبة', value: 3},
            ]
        },
        
    },
    methods: {
        getclasses() {
            const post = new FormData();
            post.append("type","getPostedCOA")
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;    
                this.classes = res.results.data;
            });
        },
        addUnit() {
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','addUnits');
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            Object.keys(this.unit).forEach(key => {
                post.append('data[units]['+key+']',this.unit[key]);
            })
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.getUnits();
                    this.$parent.getUnits();
                }
            })
        },
        resetValues(data){
            this.slide_title = data.title;
            this.unit = data.unit;
        },
        getUnits(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const post = new FormData();
            post.append('type','getUnits');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[units]',0)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.units = response.data.results.data;
                    this.unit_types = response.data.results.unit_types;
                }
            })
        }
    },
    created() {
        this.getclasses();
    },
}
</script>